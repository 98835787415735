import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled, { keyframes } from 'styled-components'
import Typewriter from 'typewriter-effect'
import LoopVideo from './assets/vid_fon.mp4'
import Glitch from 'components/glitch'
import Swal from 'sweetalert2'
import ticket from 'sections/images/gw-01.png'
import msj from 'sections/images/gw-08.png'
import Cookies from 'universal-cookie';
import Idioma from 'sections/idiomas/contents.json'
import $ from 'jquery';
import AnimatedHeading from 'components/animated-heading'
import { Row, Col, Container } from 'react-bootstrap'
import tw from 'sections/images/gw-06.png'
import fb from 'sections/images/gw-05.png'
import dsc from 'sections/images/gw-07.png'


class Video_list extends React.Component {

    constructor(props) {
        const cookies = new Cookies();
        super(props)
        let title=""
        let a=cookies.get('idioma')
        let contenido2=""
        let paso1=""
        let paso2=""
        let paso3=""
        let paso4=""
        let paso5=""
        let recompenza=""
        let contenido=""
        let frase_pie=""
        if(a=="ingles"){
             contenido2=Idioma.ingles.lista_blanca.contenido2;
             paso1=Idioma.ingles.lista_blanca.paso1;
             paso2=Idioma.ingles.lista_blanca.paso2;
             paso3=Idioma.ingles.lista_blanca.paso3;
             paso4=Idioma.ingles.lista_blanca.paso4;
             paso5=Idioma.ingles.lista_blanca.paso5;
             recompenza=Idioma.ingles.lista_blanca.recompenza;
             contenido=Idioma.ingles.lista_blanca.contenido;
             frase_pie=Idioma.ingles.lista_blanca.frase_pie;
        }else{
             contenido2=Idioma.espanol.lista_blanca.contenido2;
             paso1=Idioma.espanol.lista_blanca.paso1;
             paso2=Idioma.espanol.lista_blanca.paso2;
             paso3=Idioma.espanol.lista_blanca.paso3;
             paso4=Idioma.espanol.lista_blanca.paso4;
             paso5=Idioma.espanol.lista_blanca.paso5;
             recompenza=Idioma.espanol.lista_blanca.recompenza;
             contenido=Idioma.espanol.lista_blanca.contenido;
             frase_pie=Idioma.espanol.lista_blanca.frase_pie;
        }
    
        this.state={
             contenido2,
            paso1,
            paso2,
            paso3,
            paso4,
            paso5,
            recompenza,
            contenido,
            frase_pie,
            visual:0,
        }
    }
    updateDimensions = () => {
        if (this.state.height !== window.innerHeight) {
            this.setState({height: window.innerHeight})
        }
        if (this.state.width !== window.innerWidth) {
            this.setState({width: window.innerWidth})
        }
    }

    
    componentDidMount() {
     
        this.setState({height: window.innerHeight, width: window.innerWidth})
        window.addEventListener('resize', this.updateDimensions)
        document.body.addEventListener('mousemove', (e) => {
            var amountMovedX = (e.clientX * -.1 / 8);
            var amountMovedY = (e.clientY * -.1 / 8);
            var x = document.getElementsByClassName("parallax-hero-item");
            var i;
            for (i = 0; i < x.length; i++) {
              x[i].style.transform='translate(' + amountMovedX + 'px,' + amountMovedY + 'px)'
            }
        });
    }
    
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions)
    }

    desaparecer_imagen(){
        this.setState({visual:1})

    }


        uno=false;
         dos=false;
         tres=false;
         cuatro=false;
         cinco=false;
         url ="";
        validar(paso){
           
                switch (paso) {
                    case 1:
                        $("#uno").removeClass(" btn-primary").addClass(" btn-success");
                        this.uno=true;
                        this.url = "https://www.instagram.com/adworkchain/?igshid=YmMyMTA2M2Y="
                        window.open(this.url, '_blank');
                    break;
                    case 2:
                        $("#dos").removeClass(" btn-primary").addClass(" btn-success");
                        this.dos=true;
                        this.url = "https://twitter.com/adworkchain?s=21&t=sHNrxA1aMZ2Doi-ud8WDww"
                        window.open(this.url, '_blank');
                    break;
                    case 3:
                        $("#tres").removeClass(" btn-primary").addClass(" btn-success");
                        this.tres=true;
                        this.url = "https://www.instagram.com/cryptoversox/?igshid=YmMyMTA2M2Y="
                        window.open(this.url, '_blank');
                    break;
                    case 4:
                        $("#cuatro").removeClass(" btn-primary").addClass(" btn-success");
                        this.cuatro=true;
                        this.url = "https://twitter.com/cryptoversox?s=21&t=TaBwS5WwL5PehD7cFMtU1A"
                        window.open(this.url, '_blank');
                    break;
                    case 5:
                        $("#cinco").removeClass(" btn-primary").addClass(" btn-success");
                        this.cinco=true;
                        this.url = "https://discord.io/Cryptoversox"
                        window.open(this.url, '_blank');
                    break;
                  
                }
               if(this.uno
                && this.dos
                && this.tres
                && this.cuatro
                && this.cinco){
                  Swal.fire(
                    'Felicidades',
                    'has obtenido un ticket ',
                    'success'
                  )
                  this.setState({visual:2})
               }
        }
    render() {

        const Section = styled.section`
            position: relative;
            .particles {
                position: absolute;
                width: 100%;
                height: 100%;
            }

            background-size: cover;
            background-repeat: no-repeat;
        `

        const VideoContainer = styled.div`
            width: 100%;
           
            display: flex;
            align-items: center;
            overflow: hidden;
            position: relative;
            video {
                position: absolute;
                
            }
            @media (max-width: 1023px) {
                width: 100%;
                
            }
        `
        const Heading = styled.div`
            .glitch {
                font-size: 110px;
                line-height: 140px;
                font-weight: 600;
                color: #fff;
                @media (max-width: 767px) {
                    font-size: 40px;
                    line-height: 50px;
                }
            }
        `

        const SubHeading = styled.h2`
            font-size: 18px;
            font-weight: 300;
            color: #ccc;
            text-transform: uppercase;
            letter-spacing: 4px;
        `
        const Type = styled.div`
            font-size: 50px;
            line-height: 50px;
            color: #fff;
            text-transform: uppercase;
            margin-left: 6px;
            @media (min-width:768px) and (max-width:1500px) {
                font-size: 23px;
                line-height: 20px;
            }
            @media (max-width:767px) {
                font-size: 20px;
                line-height: 20px;
            }
            span {
                font-family: Teko;
            }
        `
        const gradientAnimation = keyframes`
            0% {
              background-position: 15% 0%;
            }
            50% {
              background-position: 85% 100%;
            }
            100% {
              background-position: 15% 0%;
            }
          `
          

        const HeadingBox = styled.div`
            height: 500px;
            width: 900px;
            margin: auto;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            &:after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                border-radius: 5px;
                background: linear-gradient(120deg, #04e5e5, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
                background-size: 300% 300%;
                clip-path: polygon(0% 100%, 10px 100%, 10px 10px, calc(100% - 10px) 10px, calc(100% - 10px) calc(100% - 10px), 10px calc(100% - 10px), 10px 100%, 100% 100%, 100% 0%, 0% 0%);
            }
            &.animate:after {
                animation: ${gradientAnimation} 2s ease-in-out infinite;
            }
            @media (max-width: 767px) {
                height: 350px;
            }
        `

        const Resume = styled.a`
            color: #fff;
            text-decoration: none;
            border-bottom: 2px solid;
            font-weight: 300;
            transition: .5s;
            margin-top: 40px;
            &:hover {
                color: #fff;
                text-decoration: none;
                border-color: #04e5e5;
            }
        `
        const ImageBox = styled.div`
        
      
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        overflow: hidden;
        @media (max-width:767px) {
            min-height: 400px;
            padding: 0;
        }
    `

    const example = keyframes`
    0% { 
        width:600px;
    }
    
    50% {
        width:590px;
    }
    
    100% {
        width:600px;
    }
        `
        const example1 = keyframes`
        0% { 
            width:300px;
        }
        
        50% {
            width:290px;
        }
        
        100% {
            width:300px;
        }
            `

    const Image = styled.img`
   
        width:600px;
      
        @media (min-width: 767px) { 
            animation-name:  ${example};
            animation-duration: 2s;
            animation-iteration-count: infinite;
        }
        @media (max-width: 767px) {
            width: 300px;
            example1
            animation-name:  ${example1};
            animation-duration: 2s;
            animation-iteration-count: infinite;
        }
       
    `

    const Image1 = styled.img`
   
        width:300px;
      
       
        @media (max-width: 767px) {
            width: 150px;
        }
       
    `
    

        function aparecer(id){
            desaparecer()
                var ids="#"+id;
                $(ids).show()
              
            }
    
        function desaparecer(){
            for (let index = 1; index <= 6; index++) {
                var ids="#p"+index
                $(ids).hide()
            }
        }
        
    
        function guardar_billetera(){
           let bile=$("#billetera").val()
           
            
            $.ajax({
                data:{billetera:bile},
                type:"POST",
                url: "https://cryptoversox.com/api_billetera/grabar.php"
            }).then(function(data) {
                console.log("ok")
            });
          Swal.fire(
            'BUEN TRABAJO',
            'ESTAS MAS CERCA DEL METAVERSO',
            'success'
          )
         
        }
    
        return (
            <Section id="home">
                <VideoContainer style={{height: `${this.state.width > 500 ? this.state.height : 1000}px`}}>
                    <video autoPlay="autoplay" loop="loop" muted style={{height: `${this.state.width >= 1023? "110%": "80%"}`}}>
                        <source src={LoopVideo} type="video/mp4" />
                    </video>
                   
                        
                        <div className='container'>
                        <div className='row justify-content-center'>
                            <div id='inicio' className='col-md-12 align-items-center' style={{display:`${this.state.visual == 0? "": "none"}`}}>
                                <h4 className='text-center text-cyan'>{this.state.contenido}</h4>
                            
                                <Image src={ticket} className="rounded mx-auto d-block pb-4" alt="Responsive image" onClick={()=>this.desaparecer_imagen()} />
                                
                                <h4 className='text-center text-cyan'>{this.state.frase_pie}</h4>
                            </div>
                            <div className='col-md-12 text-left pt-5 mt-5 ' id="botones" style={{display:`${this.state.visual == 1? "": "none"}`}}>
                              
                               <h4 className='text-center text-cyan pt-5 mt-5'>{this.state.contenido2}</h4>
                                <h4  className='text-cyan text-center'><img src={fb} width="50px"/> {this.state.paso1}  <button type="button" class="btn btn-primary " onClick={()=>this.validar(1)} id="uno">Seguir</button></h4> 
                                <h4 className='text-cyan text-center'><img src={tw} width="50px"/> {this.state.paso2}  <button type="button" class="btn btn-primary "  onClick={()=>this.validar(2)}  id="dos">Seguir</button></h4>
                                <h4 className='text-cyan text-center'><img src={fb} width="50px"/> {this.state.paso3}  <button type="button" class="btn btn-primary "  onClick={()=>this.validar(3)}  id="tres">Seguir</button></h4>
                                <h4 className='text-cyan text-center'><img src={tw} width="50px"/> {this.state.paso4}  <button type="button" class="btn btn-primary "  onClick={()=>this.validar(4)}  id="cuatro">Seguir</button></h4>
                                <h4 className='text-cyan text-center'><img src={dsc} width="50px"/> {this.state.paso5}  <button type="button" class="btn btn-primary " onClick={()=>this.validar(5)}  id="cinco">Seguir</button></h4>
                                <button type="button" class="btn btn-primary text-center" style={{display:'none'}} id="recompenza">{this.state.recompenza}</button>
                                
                                <Image1 src={msj} className="rounded float-right " alt="Responsive image"  />
                            </div>
                            <div id='billeteras' style={{display:`${this.state.visual == 2? "": "none"}`}} className="col-md-12 align-items-center">
                              <div class="form-group text-center">
                              <h4 className='text-center text-cyan py-4'>Escribe billetera</h4>
                                      <input type="text" name="name" id="billetera" className='form-control' placeholder=''/>
                                      <br></br>
                                      <input type="submit" value="Verificar"  onClick={()=>guardar_billetera()}/>
                              </div>
                            </div>
                           
                        </div>
                    </div>
                    
                    
                </VideoContainer>
            </Section>
        )
    }

    shapes() {

        const MoveUp = keyframes`
            0% { 
                transform: translateY(0);
            }
            100% {
                transform: translateY(6px);
            }
        `

        const MoveDown = keyframes`
            0% { 
                transform: translateY(0);
            }
            100% {
                transform: translateY(40px);
            }
        `
        const Shape = styled.img`
            position: absolute;
            height: 50px;
            &.move-up {
                animation: ${MoveUp} 5s infinite  alternate;
            }
            &.move-down {
                animation: ${MoveDown} 5s infinite  alternate;
            }
            @media (max-width: 767px) {
                height: 20px;
            }
        `
        


        return this.props.shapes.map((value, index) => {
            return (
                <Shape
                    style={{
                        left: `${(index+1) * 10}%`,
                        bottom: `${Math.random() *
                        (+((index+1) % 2 === 0 ? 10 : 90) - +((index+1) % 2 === 0 ? 5 : 80)) +
                        +((index+1) % 2 === 0 ? 5 : 80)}%`,
                    }}
                    key={index}
                    src={value.node.childImageSharp.fluid.src}
                    alt="shape"
                    className={Math.floor(Math.random() * 10) % 2 === 0 ? "move-up" : "move-down"}
                />
            )
        })
    }
}

export default props => (
    <StaticQuery
      query={graphql`
      query {
        shapes: allFile(filter: {extension: {regex: "/(png)/"}, relativeDirectory: {eq: "shapes"}}) {
          edges {
            node {
              childImageSharp {
                fluid(maxWidth: 100) {
                  src
                }
              }
            }
          }
        }
      }    
      `}
      render={({ shapes }) => <Video_list shapes={shapes.edges} {...props} />}
    />
  )